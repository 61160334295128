import React, { useState } from "react";
import AdminOpportunityForm from "./AdminOpportunityForm";
import AdminTargetForm from "./AdminTargetForm";
import AdminHistory from "./AdminHistory";

const AdminPanelOpps = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [activeTab, setActiveTab] = useState("add");

  const handleSuccess = () => {
    setActiveTab("history");
  };

  return (
    <div className="max-w-screen-xl mx-auto p-4">
      <div className="mb-6 flex gap-4">
        <input
          type="text"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="px-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter User ID"
        />
      </div>

      <div className="mb-6">
        <nav className="flex gap-4 border-b">
          <button
            onClick={() => setActiveTab("add")}
            className={`py-2 px-4 font-medium ${
              activeTab === "add"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            Add New
          </button>
          <button
            onClick={() => setActiveTab("history")}
            className={`py-2 px-4 font-medium ${
              activeTab === "history"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            User History
          </button>
        </nav>
      </div>

      {activeTab === "add" ? (
        <div className="grid md:grid-cols-2 gap-6">
          <AdminOpportunityForm
            selectedUser={selectedUser}
            onSuccess={handleSuccess}
          />
          <AdminTargetForm
            selectedUser={selectedUser}
            onSuccess={handleSuccess}
          />
        </div>
      ) : (
        <AdminHistory selectedUser={selectedUser} />
      )}
    </div>
  );
};

export default AdminPanelOpps;
