import React, { useState, useEffect } from "react";
import deleteImg from "../../../../assets/delete.png";
import Dropdown from "../../../Usables/Dropdown/Dropdown.js";
import Domains from "./Domains.js";
import InputField from "../../../Usables/InputField";
import "./index.css";

const InternDetails = ({ onInternDetails, initialData }) => {
  // Initialize state with initialData if provided
  const [show, setShow] = useState(!initialData);
  const [domains, setDomains] = useState(initialData?.domains || null);
  const [stipend, setStipend] = useState(initialData?.stipend || "");
  const [remoteInternship, setRemoteInternship] = useState(
    initialData?.remoteInternship || false
  );
  const [regions, setRegions] = useState(
    initialData?.regions || [
      {
        id: 1,
        regionName: "Select Region",
        subRegions: [],
        check: false,
      },
    ]
  );

  const handleChange = () => {
    setShow(!show);
  };

  const handleDomains = (e) => {
    let temp = [];
    Object.keys(e).forEach(function (key) {
      temp = [...temp, e[key].value];
    });
    temp.sort();
    setDomains(JSON.stringify(temp));
    
    // Call onInternDetails with updated data
    updateInternDetails(JSON.stringify(temp), stipend, remoteInternship, regions);
  };

  const handleStipend = (e) => {
    setStipend(e.target.value);
    // Call onInternDetails with updated data
    updateInternDetails(domains, e.target.value, remoteInternship, regions);
  };

  const handleRemoteInternship = () => {
    const newValue = !remoteInternship;
    setRemoteInternship(newValue);
    // Call onInternDetails with updated data
    updateInternDetails(domains, stipend, newValue, regions);
  };

  // Helper function to update parent component
  const updateInternDetails = (domains, stipend, remote, regions) => {
    const internDetails = {
      domains,
      stipend,
      remoteInternship: remote,
      regions,
    };
    onInternDetails(internDetails);
  };

  // Update parent component when the component mounts if there's initial data
  useEffect(() => {
    if (initialData) {
      updateInternDetails(domains, stipend, remoteInternship, regions);
    }
  }, []);

  return (
    <div className="internDWrapper">
      {show ? (
        <div className="internCheckbox">
          <input
            type="checkbox"
            checked={!show}
            className="checkBoxInput"
            onChange={handleChange}
          />
          Are you interested in an internship?
        </div>
      ) : (
        <div className="internForm">
          <div className="internFormHeader">
            <div className="internFormHeading">Internship Details</div>
            <div className="internFormShow" onClick={handleChange}>
              <img src={deleteImg} alt="/" />
            </div>
          </div>
          <div className="internFormBottom">
            <div className="inputIntern">
              <Dropdown
                name="futureFields"
                isMulti
                value={domains}
                options={Domains}
                placeHolder="Interested fields of study in the future"
                onChange={handleDomains}
              />
            </div>
            <div className="inputIntern">
              <InputField
                type="text"
                value={stipend}
                placeholder="Expected Stipend"
                name="stipend"
                onChange={handleStipend}
              />
            </div>
          </div>
          <div className="internCheckbox">
            <div className="checkBoxRemoteIntern">
              <input
                type="checkbox"
                checked={remoteInternship}
                className="rememberCheckBox"
                onChange={handleRemoteInternship}
              />
            </div>
            <div className="remoteText">
              Are you interested in remote internships?
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InternDetails;