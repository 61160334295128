import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Usables/Sidebar";
import Header from "../../Headers/HeaderProfile";
import Mentors from "./Mentors";
import "./index.css";
import OpportunityDetail from "./OpportunityDetail";
import { TailSpin } from "react-loader-spinner";
import axios from "../../../api/axios.js";

const OpportunityDetails = () => {
  const location = useLocation();
  const title = location.state?.title || "";
  const loc = location.state?.loc || "";
  const desc = location.state?.desc || "";

  const [mentorArr, setMentorArr] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMentors = async () => {
    try {
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find me three mentors for an opportunity whose title is ${title}, description is ${desc} and the opportunity is in ${loc}`,
        }
      );
      console.log("Response data after OPGURURAG Mentor API is =>", response.data);
      setMentorArr(response.data);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching Mentors from OPGURURAG Mentor API :", error);
      setLoading(false); // Also set loading to false if there's an error
    }
  };

  useEffect(() => {
    // Call fetchMentors when component mounts
    fetchMentors();
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header />
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar />
        </div>
        <div className="opportunityDetailsRight">
          {loading ? (
            <div className="loaderWrapper">
              <h1>Loading opportunity information and related mentors</h1>
              <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#dfa114"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className="opportunityInformation">
              <div className="oppDetails">
                <OpportunityDetail />
              </div>
              <div className="mentors">
                <Mentors mentorArr={mentorArr} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;