import React, { useState, useEffect } from "react";
import "./index.css";
import Regions from "./Regions";

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

export default function Modal(props) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const arr = props.regions;
  const setArr = props.setRegions;

  let displayArr = arr.filter((region) => {
    return region.regionName !== "Select Region";
  });

  const removeOption = (option) => {
    return arr.filter((o) => o.regionName !== option.regionName);
  };
  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    //Find index of specific object using findIndex method.
    setArr(newValue);
  };

  return (
    <div className="regionFinalWrapper">
      <button
  onClick={(e) => {
    e.preventDefault(); // Prevent form submission
    toggleModal();
  }}
  className="btn-modal"
  type="button" // Explicitly set button type
  style={{ width: "100%" }}
>
        <div className="regionTagWrapper">
          {displayArr.length != 0 && (
            <span className="regionLabel">Interested Regions</span>
          )}
          {displayArr.length > 0
            ? displayArr.map((region, key) => {
                return (
                  <div className="regionTag">
                    <div className="regionTagName" style={{ display: "flex" }}>
                      {region.regionName}
                      <span
                        onClick={(e) => onTagRemove(e, region)}
                        className="dropdown-tag-close"
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                );
              })
            : "Interested Regions"}
        </div>
      </button>
      {modal && (
        <div className="regionModal">
          <div 
  onClick={(e) => {
    e.preventDefault();
    toggleModal();
  }} 
  className="overlay"
>
</div>
          <div className="modal-content-regions">
            <div className="regionModalHeading">Interested Regions</div>
            <Regions
              arr={arr}
              setArr={setArr}
              toggleModal={toggleModal}
            ></Regions>
            <button 
  className="close-modal" 
  onClick={(e) => {
    e.preventDefault();
    toggleModal();
  }}
  type="button"
>
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
