import React from "react";
import { GraduationCap, Briefcase, MapPin } from "lucide-react";
import Header from "../../Headers/HeaderProfile";
import Sidebar from "../../Usables/Sidebar";
import Himanshu from "../../../assets/Himanshu.jpeg";
import Ishaan from "../../../assets/Ishaan.jpeg";
import Sarthak from "../../../assets/sarthakImg.jpeg";
import Siddharth from "../../../assets/Siddharth.jpeg";
import Umang from "../../../assets/Umang.jpg";

const ExpertProfile = ({ mentor }) => (
  <div className="mb-8 p-6 rounded-xl transition-all duration-300 hover:bg-gray-50 hover:shadow-lg cursor-pointer group">
    <div className="flex gap-8 justify-between">
      <div className="flex-1">
        <h3 className="text-4xl font-bold mb-3 text-gray-900">{mentor.name}</h3>

        <div className="space-y-4">
          <div className="flex items-start gap-2">
            <MapPin size={20} className="mt-1 text-gray-500 flex-shrink-0" />
            <div>
              <span className="font-semibold text-gray-700">Location : </span>
              <span className="text-gray-600">{mentor.location}</span>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <GraduationCap
              size={20}
              className="mt-1 text-gray-500 flex-shrink-0"
            />
            <div>
              <span className="font-semibold text-gray-700">
                Educational Background :{" "}
              </span>
              <span className="text-gray-600">{mentor.education}</span>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <Briefcase size={20} className="mt-1 text-gray-500 flex-shrink-0" />
            <div>
              <span className="font-semibold text-gray-700">
                Current Position :{" "}
              </span>
              <span className="text-gray-600">{mentor.description}</span>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center gap-4">
          <div className="inline-flex items-center px-3 py-1.5 border border-gray-200 rounded-lg bg-gray-50/50 text-gray-700">
            <Briefcase size={16} className="mr-2 text-gray-500" />
            {mentor.type}
          </div>
        </div>
      </div>

      <div className="w-1/5 h-4/6 flex-shrink-0">
        <img
          src={mentor.image}
          alt={mentor.name}
          className="w-full h-full object-cover rounded-full shadow-md transition-all duration-300 group-hover:scale-[1.02]"
        />
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full ring-1 ring-black/5 pointer-events-none"></div>
      </div>
    </div>
  </div>
);

const YourMentors = () => {
  const mentors = [
    {
      id: 1,
      name: "Sarthak Ray",
      location: "Dubai, UAE",
      education:
        "Electrical Engineering from IIT Bombay, Research Intern at Loughbrogh University",
      description: "Product Owner at Derq, UAE",
      type: "Systems and Software Engineer",
      image: Sarthak,
    },
    {
      id: 2,
      name: "Himanshu Upadhyay",
      location: "Dubai, UAE",
      education:
        "B.Tech in Civil Engineering from IIT Delhi, Masters in AI from Khalifa Univerisity",
      description: "AI Researcher at Avrioc Technologies",
      type: "AI Research, Reasearch in Mathematics",
      image: Himanshu,
    },
    {
      id: 3,
      name: "Ishaan Jain",
      location: "Delhi, India",
      education: "B.Tech in Civil Engineering from IIT Roorkee",
      description: "AI Developer at Renaiscent, ex-Data Engineer at Axis",
      type: "Full Stack Developer, Product Management",
      image: Ishaan,
    },

    {
      id: 4,
      name: "Siddharth Ray",
      location: "Malaysia",
      education:
        "Final Year Computer science Student at The University of Nottingham Malaysia",
      description:
        "Technical Lead at Nexicorn, working on large-scale distributed systems",
      type: "Tech Leadership",
      image: Siddharth,
    },
    {
      id: 5,
      name: "Umang Singh",
      location: "Roorkee, India",
      education: "3rd year student at IIT Roorkee",
      description: "Full Stack Intern at Zingy, DSA specialist",
      type: "Software Development, JEE Mentorship",
      image: Umang,
    },
  ];

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header />
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar />
        </div>
        <div className="opportunityRight">
          <div className="content-area bg-white rounded-lg p-6 flex flex-col justify-around">
            {mentors.length > 0 ? (
              mentors.map((mentor) => (
                <ExpertProfile key={mentor.id} mentor={mentor} />
              ))
            ) : (
              <p className="text-center text-gray-500">
                No mentors are available at the moment. Check back soon for
                expert guidance! 🎓
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourMentors;
