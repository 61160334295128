import React from "react";
import PersonCard from "./PersonCard";
import CarouselTestimonials from "./Carousel";
import TestimonialsLogo from "../../../assets/TestimonialsLogo.png";
import LazyImage from "../../Usables/LazyImage";
import "./index.css";

const Testimonials = () => {
  return (
    <div className="testimonialWrapper">
      <div className="homeHeading1" style={{ marginTop: "20rem" }}>
        <span className="homeHeadingSpan" style={{ color: "#ECB344" }}>
          Voices of Transformation :
        </span>{" "}
        Hear from our growing community.
      </div>
      <div className="testiHeading2">Don’t just take our word for it</div>
      <div className="testiCarousel">
        <div className="testiImage">
          <LazyImage
            src={TestimonialsLogo}
            alt="Testimonials Image"
            style={{ width: "100%" }} // Optional: Custom styles for the image
          />
        </div>
        <div className="testiCarouselDiv">
          <CarouselTestimonials></CarouselTestimonials>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
