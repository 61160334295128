const OpGuruRoutes = [
  {
    name: "Profile",
    linkTo: "profile",
  },
  {
    name: "Your Mentors",
    linkTo: "yourMentors",
  },
  {
    name: "Monitored weekly targets",
    linkTo: "mentorTargets",
  },
  {
    name: "AI Picks",
    linkTo: "opportunities",
  },
  {
    name: "Mentor Picks",
    linkTo: "mentorOpps",
  },
];

export default OpGuruRoutes;
