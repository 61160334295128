import "./App.css";
import React from "react";
import Homepage from "./components/Homepage";
import Blogs from "./components/Blogs/AllBlogs.js";
import Collabs from "./components/Collabs";
import AboutUs from "./components/AboutUs";
import Login from "./components/login/login.js";
import Footer from "./components/Footer/Footer.js";
import ResumePicker from "./components/Usables/ResumePicker";
import LinkExpired from "./components/Errors/ExpiredLink.js";
import Register from "./components/register/register.js";
import UserType from "./components/SelectChoices/UserType.js";
import SelectClass from "./components/SelectChoices/SelectClass.js";
import CreateIDsignup from "./components/register/CreateID/index.js";
import Below8thForm from "./components/userForms/Below8th";
import Class9th10th from "./components/userForms/9-10th/ninetenth.js";
import Class11th12th from "./components/userForms/11-12th";
import Parents from "./components/userForms/Parents";
import BachelorsForm from "./components/userForms/Bachelors";
import MastersForm from "./components/userForms/Masters";
import PhdForm from "./components/userForms/Phd";
import PersonalDetailsForm from "./components/userForms/personalDetails/personalDetails.js";
import Toast from "./Toast.js";
import CurrentEducation from "./components/SelectChoices/Education.js";
import ForgotPassPhno from "./components/forgotpassword/phnoForgotPassword/ForgotPassPhno.js";
import ForgotPassEmail from "./components/forgotpassword/emailForgotPassword/ForgotPassEmail.js";
import ResetpasswordEmail from "./components/forgotpassword/newpasswordemail/newpasswordemail.js";
import ResetpasswordPhno from "./components/forgotpassword/newpasswordphno/newpasswordphno.js";
import ResetSuccess from "./components/forgotpassword/ResetSuccess/ResetSuccess.js";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout.js";
import Opportunities from "./components/Opportunities";
import BachelorsExam from "./components/userForms/Bachelors/BachelorsExam";
import RequireAuth from "./components/RequireAuth.js";
import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Profile from "./components/Profile";
import PersistLogin from "./components/PersistLogin";
import ChatBot from "./ChatBot/ChatBot.js";
import OpportunityDetails from "./components/Opportunities/OpportunityDetails/index.js";
import BlogEditor from "./components/Blogs/BlogEditor.js";
import BlogDetail from "./components/Blogs/BlogDetail.js";
import ReactGA from "react-ga4";
import AdminPanelOpps from "./components/SpecialOps/AdminPanel/index.js";
import MentorOpps from "./components/SpecialOps/StudentPanel/MentorOpps.js";
import MentorTargets from "./components/SpecialOps/StudentPanel/MentorTargets.js";
import YourMentors from "./components/SpecialOps/YourMentors/index.js";

const trackingId = "G-01ZKP7MB93";
ReactGA.initialize(trackingId);

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hittype: "pageview", page: location.pathname });
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes*/}
          <Route path="/" element={<Homepage />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotpassword/phno" element={<ForgotPassPhno />} />
          <Route path="forgotpassword/email" element={<ForgotPassEmail />} />
          <Route
            path="forgotpassword/:id/:token"
            element={<ResetpasswordEmail />}
          />
          <Route
            path="forgotpassword/phno/resetpassword"
            element={<ResetpasswordPhno />}
          />
          <Route path="passwordresetsuccess" element={<ResetSuccess />} />
          <Route path="home" element={<Homepage />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="ourcollaborations" element={<Collabs />} />
          <Route path="linkExpired" element={<LinkExpired />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="enterdetails" element={<CreateIDsignup />} />
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="toast" element={<Toast />} />
          <Route path="addOpps" element={<AdminPanelOpps />} />
          <Route path="mentorOpps" element={<MentorOpps />} />
          <Route path="mentorTargets" element={<MentorTargets />} />
          <Route path="yourmentors" element={<YourMentors />} />

          {/* Protected Routes - Both USER and ADMIN */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={["USER", "ADMIN"]} />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="opportunities" element={<Opportunities />} />
              <Route
                path="opportunitydetails"
                element={<OpportunityDetails />}
              />
              <Route path="createID" element={<CreateIDsignup />} />
              <Route path="resumepicker" element={<ResumePicker />} />
            </Route>

            {/* Admin Only Routes */}
            <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
              <Route path="admin">
                <Route path="addBlog" element={<BlogEditor />} />
              </Route>
            </Route>

            {/* User Registration Flow Routes */}
            <Route element={<RequireAuth allowedRoles={["USER"]} />}>
              <Route path="usertype" element={<UserType />} />
              <Route path="selectclass" element={<SelectClass />} />
              <Route path="personaldetails" element={<PersonalDetailsForm />} />
              <Route path="selectEducation" element={<CurrentEducation />} />
              <Route path="11-12th" element={<Class11th12th />} />
              <Route path="9-10th" element={<Class9th10th />} />
              <Route path="below8thform" element={<Below8thForm />} />
              <Route path="parents" element={<Parents />} />
              <Route path="bachelorDetails" element={<BachelorsForm />} />
              <Route path="mastersDetails" element={<MastersForm />} />
              <Route path="phdDetails" element={<PhdForm />} />
              <Route path="tester" element={<BachelorsExam />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
