import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Usables/Sidebar";
import Header from "../Headers/HeaderProfile";
import Opportunity from "./Opportunity";
import AIMessage from "./AIMessage";
import LatestOpps from "./LatestOpportunities";
import Recommendations from "./Recommendations/index.js";
import axios from "../../api/axios.js";
import "./index.css";
import useAuth from "../../hooks/useAuth.js";
import { TailSpin } from "react-loader-spinner";
import UseAnalyticsTrack from "../../hooks/useAnalytics.js";

const Opportunities = () => {
  const gaEventTrack = UseAnalyticsTrack("Oppotunities");
  gaEventTrack("Opportunities Open", "Opportunities Page");
  const [oppArr, setOppArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await axios.get("/api/getOpps", {
          params: {
            userID: auth.userID,
          },
        });
        console.log("response is ", response);
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const opps = await response.data;
        console.log("data from saved opps is ", opps);
        setOppArr(JSON.parse(opps.oppList));
        setLoading(false);
        clearInterval(interval);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    let interval = setInterval(() => callApi(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header></Header>
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar></Sidebar>
        </div>
        <div className="opportunityRightUpdated">
          <div className="availableJobs">
            {loading ? (
              <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#dfa114"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              <Recommendations oppArr={oppArr}></Recommendations>
            )}
          </div>
          <div className="aiOpportunities">
            {/* <div className="latestOpportunities">
              <LatestOpps></LatestOpps>
            </div> */}
            <div className="aiMessage">
              <AIMessage></AIMessage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
