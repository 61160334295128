import React, { useState, useEffect } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import SearchDropdown from "../../Usables/SearchDropdown/index.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";
import { useNavigate } from "react-router-dom";
import ResumePicker from "../../Usables/ResumePicker";
import FutureFields from "../FormsData/FutureFields.js";
import OlympiadList from "../FormsData/Olympiads.js";
import CollegeList from "../FormsData/Colleges.js";
import YearOfStudy from "../FormsData/Year.js";
import RegionsModal from "../../Usables/RegionModal/index.js";
import InternDetails from "./InternshipDetails";
import SchoolBoard from "../../options/schoolBoard.js";
import useAuth from "../../../hooks/useAuth.js";
import axios from "../../../api/axios";
import BachelorExams from "../Bachelors/BachelorsExam";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CrossBtn from "./Icons/CrossIcon.js";
import FloatInputField from "../../Usables/FloatInputField/index.js";
import { useLoader } from "../../../context/LoaderContext.js";
import InternshipCollector from "../../Usables/InternshipCollecter/index.js";
import "./index.css";
import useLocalStorage from "../../../hooks/useLocalStorage.js";

const BachelorsForm = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { setIsLoading } = useLoader();

  const [step, setStep] = useLocalStorage("currentStep", 1);
  const [college, setCollege] = useLocalStorage("college", "");
  const [collegeName, setCollegeName] = useLocalStorage("collegeName", "");
  const [year, setYear] = useLocalStorage("year", "");
  const [schoolboard, setSchoolBoard] = useLocalStorage("schoolboard", "");
  const [tenthPercentage, setTenthPercentage] = useLocalStorage(
    "tenthPercentage",
    ""
  );
  const [err, setErr] = useState(null); // Keep as useState since it's UI state
  const [schoolboard2, setSchoolBoard2] = useLocalStorage("schoolboard2", "");
  const [twelthPercentage, setTwelthPercentage] = useLocalStorage(
    "twelthPercentage",
    ""
  );
  const [futurefields, setFutureFields] = useLocalStorage("futurefields", []);
  const [olympiads, setOlympiads] = useLocalStorage("olympiads", []);
  const [intern, setIntern] = useLocalStorage("intern", null);
  const [err2, setErr2] = useState(null); // Keep as useState since it's UI state
  const [fileName, setFileName] = useLocalStorage("fileName", "");
  const [tenthSchoolName, setTenthSchoolName] = useLocalStorage(
    "tenthSchoolName",
    ""
  );
  const [twelthSchoolName, setTwelthSchoolName] = useLocalStorage(
    "twelthSchoolName",
    ""
  );

  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_CATEGORY = "/api/saveCategory";

  const newRegion = [
    {
      id: 1,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ];

  const [regions, setRegions] = useLocalStorage("regions", newRegion);

  const newExam = [
    {
      type: "text",
      id: 1,
      name: "",
      date: "",
      percentage: "",
      criteria: "100 %",
    },
  ];

  const [exams, setExams] = useLocalStorage("exams", newExam);
  const clearLocalStorage = () => {
    localStorage.removeItem("college");
    localStorage.removeItem("collegeName");
    localStorage.removeItem("year");
    localStorage.removeItem("schoolboard");
    localStorage.removeItem("tenthPercentage");
    localStorage.removeItem("schoolboard2");
    localStorage.removeItem("twelthPercentage");
    localStorage.removeItem("futurefields");
    localStorage.removeItem("olympiads");
    localStorage.removeItem("intern");
    localStorage.removeItem("fileName");
    localStorage.removeItem("tenthSchoolName");
    localStorage.removeItem("twelthSchoolName");
    localStorage.removeItem("regions");
    localStorage.removeItem("exams");
    localStorage.removeItem("currentStep");
  };

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const handleFileRemove = () => {
    setFileName("");
  };

  const handleYear = (e) => {
    setYear(e.value);
  };

  const handleTenthBoard = (e) => {
    setSchoolBoard(e.value);
  };

  const handleTwelthBoard = (e) => {
    setSchoolBoard2(e.value);
  };

  const handleCollege = (value, label) => {
    setCollegeName(label);
    setCollege(value);
  };

  const handleTenthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr("Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr("Please enter a number between 0 and 100");
    } else {
      setErr(null); // Clear the error if the value is valid
    }
    setTenthPercentage(value);
  };

  const handleTwelthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr2("Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr2("Please enter a number between 0 and 100");
    } else {
      setErr2(null); // Clear the error if the value is valid
    }
    setTwelthPercentage(value);
  };

  const handleOlympiads = (options) => {
    setOlympiads(options);
  };

  const handleFutureFields = (options) => {
    setFutureFields(options);
  };

  const handleInternDetails = (internDetails) => {
    // Handle intern details, you can set them in the state or send to the server
    setIntern(internDetails);
    console.log("Intern Details:", JSON.stringify(internDetails));
  };

  const handleTenthSchoolName = (event) => {
    const value = event.target.value;
    setTenthSchoolName(value);
  };

  const handleTwelthSchoolName = (event) => {
    const value = event.target.value;
    setTwelthSchoolName(value);
  };

  // const handleInternshipChange = (internships) => {
  //   setPrevInternships(internships);
  // };

  const getFutureFields = (arr) => {
    let FutureFieldsLabels = [];
    let FutureFieldsValues = [];
    for (let i = 0; i < arr.length; i++) {
      FutureFieldsLabels.push(arr[i].label);
      FutureFieldsValues.push(arr[i].value);
    }
    return { FutureFieldsLabels, FutureFieldsValues };
  };

  const getOlympiads = (arr) => {
    let OlympiadLabels = [];
    let OlympiadValues = [];
    for (let i = 0; i < arr.length; i++) {
      OlympiadLabels.push(arr[i].label);
      OlympiadValues.push(arr[i].value);
    }
    return { OlympiadLabels, OlympiadValues };
  };

  const { OlympiadLabels, OlympiadValues } = getOlympiads(olympiads);

  const { FutureFieldsLabels, FutureFieldsValues } =
    getFutureFields(futurefields);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await axiosPrivate.post(
        SAVE_CATEGORY,
        {
          userID: auth.userID,
          category: "bachelors",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;

      console.log("userDetail is ", userData);
      const data = {
        userID: auth.userID,
        college: college,
        year: year,
        tenthBoard: schoolboard,
        twelthBoard: schoolboard2,
        tenthPercentage: tenthPercentage,
        twelthPercentage: twelthPercentage,
        tenthschoolname: tenthSchoolName,
        twelthschoolname: twelthSchoolName,
        olympiadpartipated: JSON.stringify(OlympiadValues),
        futurefields: JSON.stringify(FutureFieldsValues),
        exams: JSON.stringify(exams),
        interestedregions: JSON.stringify(regions),
        intern: JSON.stringify(intern),
      };
      console.log("all data is", data);

      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/bachelors", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log("Bachelors details saved successfully:", responseData);
      } else {
        console.log("The current data state is :", response1.statusText);
      }
      await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      fetchOpportunities(userData);
      // DONT PUT AWAIT IN FETCH OPPORTUNITIES
      clearLocalStorage();
      navigate("/profile");
      controller.abort();
    } catch (err) {
      // Handle abort errors explicitly
      if (err.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.log("An error occurred:", err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOpportunities = async (userData) => {
    try {
      const gender = userData.gender;
      const country = userData.country;
      const response = await axios.post(process.env.REACT_APP_RAG_URL, {
        query: `Find ten different opportunities that best match the profile of a user who is currently a Bachelor's student. The student is a ${gender}, studying at ${collegeName}, and is currently in their ${year} Year. The student is from ${country} and their fields of interest for future study include ${JSON.stringify(
          FutureFieldsLabels
        )}. They scored ${tenthPercentage}% in 10th grade from the ${schoolboard} board and ${twelthPercentage}% in 12th grade from the ${schoolboard2} board. The student is interested in opportunities in the following regions: ${JSON.stringify(
          regions
        )}. In the past, the student has participated in the following Olympiads: ${JSON.stringify(
          OlympiadLabels
        )}, and they have appeared for the following proficiency exams: ${JSON.stringify(
          exams
        )}. Please find opportunities including internships, scholarships, fellowships, exchange programs, and conferences. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, GPA requirements, current degree pursuing, eligible regions, citizenship, work experience), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
        userID: auth.userID,
      });
      console.log("Response data after OPGURURAG API is =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities from OPGURURAG :", error);
    }
  };

  const renderStepOne = () => (
    <>
      <div className="inputForm">
        <SearchDropdown
          options={CollegeList}
          onSelect={handleCollege}
          placeholderInput="Search your College"
          placeholderDropdown="College Name"
        />
        <Dropdown
          name="TenthBoard"
          value={schoolboard}
          options={SchoolBoard}
          placeHolder="TenthBoard"
          onChange={handleTenthBoard}
        />
        <FloatInputField
          name="TenthPercentage"
          placeHolder="Tenth Percentage"
          handleInput={handleTenthPercentage}
          err={err}
          inputVal={tenthPercentage}
        />
        <Dropdown
          name="TwelthBoard"
          value={schoolboard2}
          options={SchoolBoard}
          placeHolder="Twelth Board"
          onChange={handleTwelthBoard}
        />
        <FloatInputField
          name="TwelthPercentage"
          placeHolder="12th Percentage"
          handleInput={handleTwelthPercentage}
          err={err2}
          inputVal={twelthPercentage}
        />
        <FloatInputField
          name="TenthSchoolName"
          placeHolder="Tenth School Name"
          handleInput={handleTenthSchoolName}
          inputVal={tenthSchoolName}
        />
        <FloatInputField
          name="SchoolName"
          placeHolder="Twelth School Name"
          handleInput={handleTwelthSchoolName}
          inputVal={twelthSchoolName}
        />
        <Dropdown
          name="currentYear"
          value={year}
          options={YearOfStudy}
          placeHolder="Current Year of Study"
          onChange={handleYear}
        />
        <BachelorExams exams={exams} setExams={setExams} />
        <div
          className="bachelorFormInput"
          style={{ alignSelf: "flex-start", margin: "2rem" }}
        >
          <ResumePicker
            fileName={fileName}
            handleFile={handleFile}
            handleFileRemove={handleFileRemove}
          />
        </div>
        {fileName !== "" && (
          <div className="inputForm uploadedFile">
            <div
              className="inputForm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92%",
              }}
            >
              <div>Uploaded file : {fileName}</div>
            </div>
            <button className="removeBtn" onClick={handleFileRemove}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {CrossBtn}
              </div>
            </button>
          </div>
        )}
      </div>
    </>
  );

  const renderStepTwo = () => (
    <>
      <div className="inputForm FutureFields">
        <MultiSelectDropdown
          options={FutureFields}
          onSelect={handleFutureFields}
          placeholderDropdown="Interested fields in Future"
          placeholderInput="Search for interested fields"
          showSearchBar={true}
          showSelectAll={false}
        />
        <MultiSelectDropdown
          options={OlympiadList}
          onSelect={handleOlympiads}
          placeholderDropdown="Olympiads participated"
          placeholderInput="Search olympiads"
          showSearchBar={true}
          showSelectAll={false}
        />
      </div>
      <div style={{ width: "100%" }}>
        <RegionsModal
          regions={regions}
          setRegions={setRegions}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </div>
      <div style={{ width: "100%", margin: "1rem" }}>
        <InternDetails onInternDetails={handleInternDetails} />
      </div>
    </>
  );

  return (
    <div className="userFormWrapper">
      <Header />
      <div className="studentFormWrapper">
        <div className="formHeading">Registration Form for Bachelors</div>
        <div className="steps-wrapper">
          <div
            className={`step-number ${
              step === 1 ? "step-active" : "step-inactive"
            }`}
          >
            1
          </div>
          <span className="step-text">Educational Background</span>
          <div
            className={`step-number ${
              step === 2 ? "step-active" : "step-inactive"
            }`}
          >
            2
          </div>
          <span className="step-text">Preferences</span>
        </div>

        <form style={{ width: "100%", marginBottom: "1rem" }}>
          {step === 1 ? renderStepOne() : renderStepTwo()}

          <div className="navigation-buttons">
            {step > 1 && (
              <button
                onClick={() => setStep(1)}
                className="yellowBtn"
                type="button"
              >
                Previous
              </button>
            )}
            {step === 1 ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setStep(2);
                }}
                className="yellowBtn"
                type="button"
              >
                Next
              </button>
            ) : (
              <button onClick={handleClick} className="yellowBtn" type="button">
                Save and Continue
              </button>
            )}
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default BachelorsForm;
