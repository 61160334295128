import React, { useEffect, useState } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import "./index.css";
import { useNavigate } from "react-router-dom";
import CollegeList from "../FormsData/Colleges.js";
import FutureOpportunityTypesList from "../FormsData/FutureFields.js";
import YearOfStudy from "../FormsData/Year.js";
import SchoolBoard from "../../options/schoolBoard.js";
import BachelorExams from "../Bachelors/BachelorsExam";
import DegreeList from "../../options/MasterDegree.js";
import ResumePicker from "../../Usables/ResumePicker";
import RegionsModal from "../../Usables/RegionModal";
import useAuth from "../../../hooks/useAuth.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../api/axios";
import SearchDropdown from "../../Usables/SearchDropdown/index.js";
import FloatInputField from "../../Usables/FloatInputField/index.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";
import { useLoader } from "../../../context/LoaderContext.js";

const MastersForm = () => {
  // API VARIABLES
  const navigate = useNavigate();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_CATEGORY = "/api/saveCategory";
  const { setIsLoading } = useLoader();

  // variables
  const [college, setCollege] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [year, setYear] = useState(null);
  const [yearRAG, setYearRAG] = useState(null);
  const [tenthBoard, setTenthBoard] = useState(null);
  const [tenthBoardRAG, setTenthBoardRAG] = useState(null);
  const [twelthBoard, setTwelthBoard] = useState(null);
  const [twelthBoardRAG, setTwelthBoardRAG] = useState(null);
  const [currentDegreeValue, setCurrentDegreeValue] = useState(null);
  const [currentDegreeLabel, setCurrentDegreeLabel] = useState(null);
  const [futureDegreeValue, setFutureDegreeValue] = useState(null);
  const [futureDegreeLabel, setFutureDegreeLabel] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [twelthPercentage, setTwelthPercentage] = useState(null);
  const [compensation, setCompensation] = useState(null);
  const [futureOpportunityTypes, setFutureOpportunityTypes] = useState([]);
  const [err, setErr] = useState(null);
  const [err2, setErr2] = useState(null);
  const [gpa, setGPA] = useState(null);
  const [tenthSchoolName, setTenthSchoolName] = useState("");
  const [twelthSchoolName, setTwelthSchoolName] = useState("");

  const newRegion = [
    {
      id: 1,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ];

  const [regions, setRegions] = useState(newRegion);

  const newExam = [
    {
      type: "text",
      id: 1,
      name: "",
      date: "",
      percentage: "",
      criteria: "100 %",
    },
  ];

  const [exams, setExams] = useState(newExam);

  const [fileName, setFileName] = useState("");

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const handleFileRemove = () => {
    setFileName("");
  };

  const crossBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      viewBox="0 0 48 48"
    >
      <path
        fill="#f44336"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      ></path>
      <path
        fill="#fff"
        d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
      ></path>
      <path
        fill="#fff"
        d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
      ></path>
    </svg>
  );

  // Variable handlers
  const handleCollege = (value, label) => {
    setCollegeName(label);
    setCollege(value);
  };

  const handleYear = (e) => {
    setYear(e.value);
    // e.value is a number in form of string, and our database has string only
    let ind = parseInt(e.value) - 1;
    setYearRAG(YearOfStudy[ind].label);
  };

  const handleTenthBoard = (e) => {
    setTenthBoard(e.value);
    const ind = parseInt(e.value) - 1;
    setTenthBoardRAG(SchoolBoard[ind].label);
  };

  const handleTwelthBoard = (e) => {
    setTwelthBoard(e.value);
    const ind = parseInt(e.value) - 1;
    setTwelthBoardRAG(SchoolBoard[ind].label);
  };

  const handleTenthSchoolName = (event) => {
    const value = event.target.value;
    setTenthSchoolName(value);
  };

  const handleTwelthSchoolName = (event) => {
    const value = event.target.value;
    setTwelthSchoolName(value);
    console.log("school is ", twelthSchoolName);
  };

  const handleTenthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr("* Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr("* Please enter a number between 0 and 100");
    } else {
      setErr(null); // Clear the error if the value is valid
    }

    setTenthPercentage(value);
  };

  const handleTwelthPercentage = (event) => {
    const value = event.target.value;
    // Check if the value is empty or not a number
    if (value !== "" && isNaN(value)) {
      setErr2("* Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr2("* Please enter a number between 0 and 100");
    } else {
      setErr2(null); // Clear the error if the value is valid
    }

    setTwelthPercentage(value);
  };

  const handleCompensation = (event) => {
    const value = event.target.value;
    setCompensation(value);
  };

  const handleGPA = (event) => {
    const value = event.target.value;
    setGPA(value);
  };

  const handleCurrentDegree = (value, label) => {
    setCurrentDegreeLabel(label);
    setCurrentDegreeValue(value);
  };

  const handleFutureDegree = (value, label) => {
    setFutureDegreeLabel(label);
    setFutureDegreeValue(value);
  };

  const handleFutureOppsTypes = (options) => {
    setFutureOpportunityTypes(options);
  };

  const getLabelsAndValues = (arr) => {
    let labels = [];
    let values = [];
    for (let i = 0; i < arr.length; i++) {
      labels.push(arr[i].label);
      values.push(arr[i].value);
    }
    return { labels, values };
  };

  const { labels: futureOppsTypesLabels, values: futureOppsTypesValues } =
    getLabelsAndValues(futureOpportunityTypes);

  const convertRegions = (regions) => {
    let finalRegions = [];
    for (let i = 0; i < regions.length; i++) {
      let region = regions[i];
      let regionName = region.regionName;
      let finalSubRegions = [];
      let subRegions = region.subRegions;
      for (let j = 0; j < subRegions.length; j++) {
        finalSubRegions.push(subRegions[j].label);
      }
      const obj = {
        continent: regionName,
        countries: JSON.stringify(finalSubRegions),
      };
      finalRegions.push(obj);
    }
    return finalRegions;
  };

  const finalRegions = convertRegions(regions);

  const newData = {
    userID: auth.userID,
    college: college,
    year: year,
    currentdegree: currentDegreeValue,
    futuredegree: futureDegreeValue,
    compensation: compensation,
    gpa: gpa,
    tenthschoolname: tenthSchoolName,
    twelthschoolname: twelthSchoolName,
    tenthBoard: tenthBoard,
    twelthBoard: twelthBoard,
    tenthPercentage: tenthPercentage,
    twelthPercentage: twelthPercentage,
    exams: JSON.stringify(exams), // Stringify the array
    interestedregions: JSON.stringify(finalRegions),
    futureOpportunityTypes: JSON.stringify(futureOppsTypesValues),
  };

  useEffect(() => {
    console.log("New data is ", newData);
  }, [newData]);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await axiosPrivate.post(
        SAVE_CATEGORY,
        {
          userID: auth.userID,
          category: "masters",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;
      const data = {
        userID: auth.userID,
        college: college,
        year: year,
        currentdegree: currentDegreeValue,
        futuredegree: futureDegreeValue,
        compensation: compensation,
        gpa: gpa,
        tenthschoolname: tenthSchoolName,
        twelthschoolname: twelthSchoolName,
        tenthBoard: tenthBoard,
        twelthBoard: twelthBoard,
        tenthPercentage: tenthPercentage,
        twelthPercentage: twelthPercentage,
        exams: JSON.stringify(exams), // Stringify the array
        interestedregions: JSON.stringify(finalRegions),
        futureOpportunityTypes: JSON.stringify(futureOppsTypesValues),
      };
      console.log("Final data after submitting is ", data);
      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/masters", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log("Masters details saved successfully:", responseData);
      } else {
        console.log(
          "The current data state iN Masters form is :",
          response1.statusText
        );
      }
      await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      fetchOpportunities(userData);
      navigate("/profile");
      controller.abort();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOpportunities = async (userData) => {
    const gender = userData.gender;
    const country = userData.country;
    try {
      const response = await axios.post(process.env.REACT_APP_RAG_URL, {
        query: `Find ten different opportunities that best match the profile of a user who is currently a Master's student. The student is a ${gender}, and is currently doing ${currentDegreeLabel} at ${collegeName} with a current GPA ( out of 10 ) of ${gpa}, and is currently in their ${yearRAG}. In future he wants to pursue ${futureDegreeLabel}. The student is from ${country} and their fields of interest for future study include ${JSON.stringify(
          futureOppsTypesLabels
        )}. They scored ${tenthPercentage}% in 10th grade from the ${tenthBoardRAG} board and ${twelthPercentage}% in 12th grade from the ${twelthBoardRAG} board. The student is interested in opportunities in the following regions: ${JSON.stringify(
          regions
        )}. The student have appeared for the following proficiency exams with scores as : ${JSON.stringify(
          exams
        )}. Please find opportunities including internships, scholarships, fellowships, exchange programs, and conferences. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, GPA requirements, current degree pursuing, eligible regions, citizenship, work experience), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
        userID: auth.userID,
      });
      console.log("response data after opgururag is =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities:", error);
    }
  };

  return (
    <div className="userFormWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">Registration Form for Masters</div>
        <div className="formMessage">
          Enter all Your personal details mention below
        </div>
        <form style={{ width: "100%", marginBottom: "1rem" }}>
          <div className="inputForm">
            <SearchDropdown
              options={CollegeList}
              onSelect={handleCollege}
              placeholderInput="Search your College"
              placeholderDropdown="College Name"
            />
            <br />
            <Dropdown
              name="currentYear"
              value={year}
              options={YearOfStudy}
              placeHolder="Current Year of Study"
              onChange={handleYear}
            ></Dropdown>
            <br />
            <Dropdown
              name="TenthBoard"
              value={tenthBoard}
              options={SchoolBoard}
              placeHolder="TenthBoard"
              onChange={handleTenthBoard}
            ></Dropdown>
            <FloatInputField
              name="TenthPercentage"
              placeHolder="Tenth Percentage"
              handleInput={handleTenthPercentage}
              err={err}
              inputVal={tenthPercentage}
            ></FloatInputField>
            <Dropdown
              name="TwelthBoard"
              value={twelthBoard}
              options={SchoolBoard}
              placeHolder="Twelth Board"
              onChange={handleTwelthBoard}
            ></Dropdown>
            <FloatInputField
              name="TwelthPercentage"
              placeHolder="12th Percentage"
              handleInput={handleTwelthPercentage}
              err={err2}
              inputVal={twelthPercentage}
            ></FloatInputField>
            <FloatInputField
              name="TenthSchoolName"
              placeHolder=" Tenth School Name"
              handleInput={handleTenthSchoolName}
              inputVal={tenthSchoolName}
            ></FloatInputField>
            <FloatInputField
              name="SchoolName"
              placeHolder="Twelth School Name"
              handleInput={handleTwelthSchoolName}
              inputVal={twelthSchoolName}
            ></FloatInputField>
            <FloatInputField
              name="expectedCompensation"
              placeHolder="Expected Compensation (INR Monthly)"
              handleInput={handleCompensation}
              inputVal={compensation}
            ></FloatInputField>
            <FloatInputField
              name="gpa"
              placeHolder="Current GPA ( On a scale of 10 )"
              handleInput={handleGPA}
              inputVal={gpa}
            ></FloatInputField>
            <SearchDropdown
              options={DegreeList}
              onSelect={handleCurrentDegree}
              placeholderInput="Search your degree"
              placeholderDropdown="Degree Pursuing"
            />
            <br />
            <SearchDropdown
              options={DegreeList}
              onSelect={handleFutureDegree}
              placeholderInput="Search your degree"
              placeholderDropdown="Future Degree"
            />
            <br />
            <MultiSelectDropdown
              options={FutureOpportunityTypesList}
              onSelect={handleFutureOppsTypes}
              placeholderDropdown="Opportunity types interested in"
              placeholderInput="Search for interested fields"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
            <br />
            <BachelorExams exams={exams} setExams={setExams}></BachelorExams>
          </div>
        </form>
        <div style={{ width: "100%" }}>
          <RegionsModal
            regions={regions}
            setRegions={setRegions}
          ></RegionsModal>
        </div>
        <div
          className="bachelorFormInput"
          style={{ alignSelf: "flex-start", margin: "2rem" }}
        >
          <ResumePicker
            fileName={fileName}
            handleFile={handleFile}
            handleFileRemove={handleFileRemove}
          ></ResumePicker>
        </div>
        {fileName !== "" ? (
          <div className="inputForm uploadedFile">
            <div
              className="inputForm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92%",
              }}
            >
              <div>Uploaded file : {fileName}</div>
            </div>
            <button className="removeBtn" onClick={handleFileRemove}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {crossBtn}
              </div>
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <button
          onClick={handleClick}
          style={{ width: "50%" }}
          className="yellowBtn"
        >
          Save and Continue
        </button>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default MastersForm;
