import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Navbar from "../Navbar";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./AllBlogs.css";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(8);
  
  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get("/api/blogs/getBlogs");
      setBlogs(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };

  const handleBlogClick = (slug) => {
    navigate(`/blog/${slug}`);
  };

  // Get current blogs
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const Pagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination-container">
        <button
          onClick={() => currentPage > 1 && paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-nav-button"
          aria-label="Previous page"
        >
          <ChevronLeft size={20} />
        </button>
        
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
            aria-label={`Page ${number}`}
            aria-current={currentPage === number ? 'page' : undefined}
          >
            {number}
          </button>
        ))}
        
        <button
          onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-nav-button"
          aria-label="Next page"
        >
          <ChevronRight size={20} />
        </button>
      </div>
    );
  };

  return (
    <div className="homeWrapper">
      <div className="homeNavbar">
        <Navbar />
      </div>
      <div className="blogsWrapper">
        {loading ? (
          <div className="blogs-loading">Loading blogs...</div>
        ) : error ? (
          <div className="blogs-error">{error}</div>
        ) : blogs.length === 0 ? (
          <div className="blogs-empty">No blogs found</div>
        ) : (
          <>
            <div className="blogs-grid">
              {currentBlogs.map((blog) => (
                <div
                  key={blog.id}
                  className="blog-card"
                  onClick={() => handleBlogClick(blog.slug)}
                >
                  <div className="blog-card-image">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${blog.placeholderImageUrl}`}
                      alt={blog.title}
                      crossOrigin="anonymous"
        referrerPolicy="no-referrer"
                    />
                  </div>
                  <div className="blog-card-content">
                    <h2 className="blog-card-title">{blog.title}</h2>
                    <p className="blog-card-introduction">
                      {blog.introduction.length > 150
                        ? `${blog.introduction.substring(0, 150)}...`
                        : blog.introduction}
                    </p>
                    <div className="blog-card-footer">
                      <span className="blog-card-date">
                        {new Date(blog.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </span>
                      <span className="blog-card-read-more">Read More →</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Pagination />
          </>
        )}
      </div>
    </div>
  );
};

export default Blogs;