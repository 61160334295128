import React, { useState } from "react";
import "./index.css";

const faqs = [
  {
    question: "How can Opguru help me in my career?",
    answer:
      "Opguru is an AI-driven platform that bridges the knowledge gap by providing personalized career guidance, global opportunities, and one-on-one mentorship. It empowers individuals to make informed career decisions, connect with industry experts, and access international opportunities to achieve their full potential.",
  },
  {
    question: "What is the difference between the free and premium services?",
    answer:
      "Premium services offer additional features such as personalized mentorship, exclusive access to global opportunities, and in-depth career guidance tailored to your specific goals.",
  },
  {
    question: "How does Opguru ensure the quality of its mentors?",
    answer:
      "Opguru carefully selects its mentors based on their expertise, experience, and track record in their respective fields. Mentors undergo a rigorous screening process and are regularly reviewed based on user feedback.",
  },
  {
    question: "Will Opguru offer mobile apps in the future?",
    answer:
      "Yes, Opguru plans to launch mobile applications to make it easier for users to access career guidance and opportunities on the go.",
  },
  // {
  //   question: "Can I connect with other users on Opguru?",
  //   answer:
  //     "Yes, Opguru allows users to connect with others in the community for networking, sharing experiences, and mutual support.",
  // },
  {
    question: "How can I contribute as a mentor?",
    answer:
      "If you're interested in becoming a mentor, you can apply through the Opguru platform. Our team will review your application and, if accepted, you can start guiding and inspiring others in their careers.",
  },
];

const FaqItem = ({ faq, isOpen, onClick }) => (
  <div className={`faq-item ${isOpen ? "active" : ""}`} onClick={onClick}>
    <h3 className="faq-question">
      {faq.question} <span className="faq-icon">{isOpen ? "╵" : "+"}</span>
    </h3>
    {isOpen && <p className="faq-answer">{faq.answer}</p>}
  </div>
);

const FaqPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="homeHeading1">
        <span className="homeHeadingSpan" style={{}}>
          Frequently
        </span>{" "}
        Asked
        <span className="homeHeadingSpan" style={{}}>
          Questions
        </span>{" "}
      </div>
      {faqs.map((faq, index) => (
        <FaqItem
          key={index}
          faq={faq}
          isOpen={openIndex === index}
          onClick={() => toggleFaq(index)}
        />
      ))}
    </div>
  );
};

export default FaqPage;
