import React, { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import AdminHistory from "./AdminHistory";

const AdminTargetForm = ({ selectedUser, onSuccess }) => {
  const [formData, setFormData] = useState({
    title: "",
    deadline: "",
    priority: "high",
    type: "weekly",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser) {
      toast.error("Please select a user first");
      return;
    }

    setIsSubmitting(true);
    try {
      await axios.post("/api/admin/usertargets", {
        ...formData,
        userID: selectedUser,
      });
      toast.success("Target added successfully");
      setFormData({
        title: "",
        deadline: "",
        priority: "high",
        type: "weekly",
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add target");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg border">
      <h2 className="text-lg font-semibold mb-4">Add Target</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={formData.title}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, title: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Title"
          required
        />
        <input
          type="date"
          value={formData.deadline}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, deadline: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <select
          value={formData.priority}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, priority: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="high">High Priority</option>
          <option value="medium">Medium Priority</option>
          <option value="low">Low Priority</option>
        </select>
        <select
          value={formData.type}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, type: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-300"
        >
          {isSubmitting ? "Adding..." : "Add Target"}
        </button>
      </form>
    </div>
  );
};

export default AdminTargetForm;
