import React, { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const AdminOpportunityForm = ({ selectedUser, onSuccess }) => {
  const [formData, setFormData] = useState({
    title: "",
    type: "scholarship",
    description: "",
    eligibility: "",
    benefits: "",
    location: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser) {
      toast.error("Please select a user first");
      return;
    }

    setIsSubmitting(true);
    try {
      await axios.post("/api/admin/useropportunities", {
        ...formData,
        userID: selectedUser,
      });
      toast.success("Opportunity added successfully");
      setFormData({
        title: "",
        type: "scholarship",
        description: "",
        eligibility: "",
        benefits: "",
        location: "",
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add opportunity");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg border">
      <h2 className="text-lg font-semibold mb-4">Add Opportunity</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={formData.title}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, title: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Title"
          required
        />
        <select
          value={formData.type}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, type: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="scholarship">Scholarship</option>
          <option value="competition">Competition</option>
          <option value="college_program">College Program</option>
          <option value="internship">Internship</option>
          <option value="exam">Proficiency Exam</option>
          <option value="seminar">Seminar</option>
        </select>
        <textarea
          value={formData.description}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, description: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Description"
          rows="3"
          required
        />
        <textarea
          value={formData.eligibility}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, eligibility: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Eligibility"
          rows="3"
          required
        />
        <textarea
          value={formData.benefits}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, benefits: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Benefits"
          rows="3"
          required
        />
        <input
          type="text"
          value={formData.location}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, location: e.target.value }))
          }
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Location"
          required
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-300"
        >
          {isSubmitting ? "Adding..." : "Add Opportunity"}
        </button>
      </form>
    </div>
  );
};

export default AdminOpportunityForm;
