import React, { useState, useEffect } from "react";
import {
  GraduationCap,
  Briefcase,
  Book,
  School,
  FileText,
  Trophy,
} from "lucide-react";
import Header from "../../Headers/HeaderProfile";
import Sidebar from "../../Usables/Sidebar";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import "./index.css";

const MentorOpps = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const response = await axios.get(
          `/api/admin/useropportunities/${auth.userID}`
        );
        setOpportunities(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch opportunities");
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, [auth.userID]);

  const getIcon = (type) => {
    switch (type) {
      case "scholarship":
        return <GraduationCap size={25} className="mr-2" />;
      case "internship":
        return <Briefcase size={25} className="mr-2" />;
      case "exam":
        return <FileText size={25} className="mr-2" />;
      case "college_program":
        return <School size={25} className="mr-2" />;
      case "competition":
        return <Trophy size={25} className="mr-2" />;
      default:
        return <Book size={25} className="mr-2" />;
    }
  };

  const getDisplayText = (type) => {
    return type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const OpportunityCard = ({ opportunity }) => (
    <div className="opportunity-item mb-8">
      <h3 className="text-4xl font-bold mb-3">{opportunity.title}</h3>

      <div className="space-y-3">
        <div>
          <span className="font-semibold">Location : </span>
          <span>{opportunity.location}</span>
        </div>

        <div>
          <span className="font-semibold">Eligibility : </span>
          <span>{opportunity.eligibility}</span>
        </div>

        <div>
          <span className="font-semibold">Description : </span>
          <span>{opportunity.description}</span>
        </div>
      </div>

      <div className="mt-4 flex items-center gap-4">
        <div className="inline-flex items-center px-3 py-1 border border-gray-300 rounded">
          {getIcon(opportunity.type)}
          {getDisplayText(opportunity.type)}
        </div>
      </div>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header />
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar />
        </div>
        <div className="opportunityRight">
          <div className="content-area bg-white rounded-lg p-6 flex flex-col justify-around">
            {opportunities.length > 0 ? (
              opportunities.map((opportunity) => (
                <OpportunityCard
                  key={opportunity.id}
                  opportunity={opportunity}
                />
              ))
            ) : (
              <p className="text-center text-gray-500">
                No opportunities found at the moment, but our mentors are on it!
                Stay tuned—exciting opportunities will be updated soon. 🚀
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorOpps;
