import Bell from "../../../assets/Bell.png";
import User from "../../../assets/User.png";
import Support from "../../../assets/Support.png";
import Clients from "../../../assets/Clients.png";
import Dashboard from "../../../assets/Dashboard.png";
import Employees from "../../../assets/Employees.png";

const SidebarFields = [
  {
    name: "Profile",
    Icon: User,
    linkTo: "profile",
  },
  {
    name: "Your Mentors",
    Icon: Clients,
    linkTo: "yourMentors",
  },
  {
    name: "Monitored weekly targets",
    Icon: Bell,
    linkTo: "mentorTargets",
  },
  {
    name: "AI Picks",
    Icon: Dashboard,
    linkTo: "opportunities",
  },
  {
    name: "Mentor Picks",
    Icon: Employees,
    linkTo: "mentorOpps",
  },
];

export default SidebarFields;
