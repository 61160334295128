import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const AdminHistory = ({ selectedUser }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [targets, setTargets] = useState([]);
  const [activeHistoryTab, setActiveHistoryTab] = useState("opportunities");
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserData = async () => {
    if (!selectedUser) return;

    setIsLoading(true);
    try {
      const [oppsResponse, targetsResponse] = await Promise.all([
        axios.get(`/api/admin/useropportunities/${selectedUser}`),
        axios.get(`/api/admin/usertargets/${selectedUser}`),
      ]);
      setOpportunities(oppsResponse.data);
      setTargets(targetsResponse.data);
    } catch (error) {
      toast.error("Failed to fetch user data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    console.log("checking continously");
  }, [selectedUser]);

  const handleDelete = async (type, id) => {
    try {
      await axios.delete(`/api/admin/${type}/${id}`);
      toast.success("Item deleted successfully");
      fetchUserData();
    } catch (error) {
      toast.error("Failed to delete item");
    }
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg border">
      <div className="mb-6">
        <nav className="flex gap-4 border-b">
          <button
            onClick={() => setActiveHistoryTab("opportunities")}
            className={`py-2 px-4 font-medium ${
              activeHistoryTab === "opportunities"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            Opportunities
          </button>
          <button
            onClick={() => setActiveHistoryTab("targets")}
            className={`py-2 px-4 font-medium ${
              activeHistoryTab === "targets"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            Targets
          </button>
        </nav>
      </div>

      <div className="space-y-4">
        {activeHistoryTab === "opportunities" ? (
          opportunities.length > 0 ? (
            opportunities.map((opp) => (
              <div key={opp.id} className="p-4 border rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-medium">{opp.title}</h3>
                    <p className="text-sm text-gray-500">Type: {opp.type}</p>
                    <p className="mt-2 text-gray-600">{opp.description}</p>
                    <p className="mt-2 text-xs text-gray-400">
                      Created: {new Date(opp.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleDelete("useropportunities", opp.id)}
                      className="px-3 py-1 text-sm rounded border border-red-500 text-red-500 hover:bg-red-50"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No opportunities found</p>
          )
        ) : targets.length > 0 ? (
          targets.map((target) => (
            <div key={target.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium">{target.title}</h3>
                  <p className="text-sm text-gray-500">
                    Priority: {target.priority} | Type: {target.type}
                  </p>
                  <p className="mt-2 text-gray-600">
                    Deadline: {new Date(target.deadline).toLocaleDateString()}
                  </p>
                  <p className="mt-2 text-xs text-gray-400">
                    Status: {target.completed ? "Completed" : "Pending"}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleDelete("usertargets", target.id)}
                    className="px-3 py-1 text-sm rounded border border-red-500 text-red-500 hover:bg-red-50"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No targets found</p>
        )}
      </div>
    </div>
  );
};

export default AdminHistory;
