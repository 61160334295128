// axios.js
import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
console.log("Base url is ", BASE_URL);

// Regular axios instance for public routes
const axiosPublic = axios.create({
  baseURL: BASE_URL,
});

// Add interceptor to remove auth headers for image requests
axiosPublic.interceptors.request.use((config) => {
  if (config.url?.includes('/uploads/')) {
    delete config.headers.Authorization;
  }
  return config;
});

// Private axios instance for authenticated routes
const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// Add interceptor to private instance as well
axiosPrivate.interceptors.request.use((config) => {
  if (config.url?.includes('/uploads/')) {
    delete config.headers.Authorization;
  }
  return config;
});

export default axiosPublic;
export { axiosPrivate };