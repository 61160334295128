import React, { useState, useEffect } from "react";
import { GraduationCap } from "lucide-react";
import Header from "../../Headers/HeaderProfile";
import Sidebar from "../../Usables/Sidebar";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth"; // Import if you have auth context
import "./index.css";

const MentorTargets = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth(); // Get user info from auth context

  const fetchTargets = async () => {
    try {
      const response = await axios.get(`/api/admin/usertargets/${auth.userID}`);
      setTasks(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch targets");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTargets();
  }, [auth.userID]);

  const toggleTaskStatus = async (taskId) => {
    try {
      await axios.patch(`/api/admin/usertargets/${taskId}/toggle`);
      fetchTargets(); // Refresh the list after toggling
      toast.success("Task status updated");
    } catch (error) {
      toast.error("Failed to update task status");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header />
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar />
        </div>
        <div className="opportunityRight">
          <div className="content-area bg-white rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-6">Application Tasks</h2>

            {/* Pending Tasks */}
            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">To Do</h3>
              <div className="space-y-3">
                {tasks
                  .filter((task) => !task.completed)
                  .map((task) => (
                    <div
                      key={task.id}
                      className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg"
                    >
                      <input
                        type="checkbox"
                        checked={false}
                        onChange={() => toggleTaskStatus(task.id)}
                        className="w-5 h-5 rounded"
                      />
                      <div>
                        <p className="font-medium">{task.title}</p>
                        <p className="text-xl text-gray-500">
                          Deadline:{" "}
                          {new Date(task.deadline).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Completed Tasks */}
            <div>
              <h3 className="text-xl font-semibold mb-4">Completed</h3>
              <div className="space-y-3">
                {tasks
                  .filter((task) => task.completed)
                  .map((task) => (
                    <div
                      key={task.id}
                      className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg"
                    >
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => toggleTaskStatus(task.id)}
                        className="w-5 h-5 rounded"
                      />
                      <div>
                        <p className="text-2xl line-through text-gray-500">
                          {task.title}
                        </p>
                        <p className="text-lg text-gray-400">
                          Deadline:{" "}
                          {new Date(task.deadline).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorTargets;
