import React, { useState } from "react";
import axios from "../../../../../api/axios";
import useAuth from "../../../../../hooks/useAuth";
import "./index.css";

const MentorCard = ({
  name,
  profileImg,
  location,
  description,
  profession,
  students_mentored,
  review_stars,
}) => {
  const { auth } = useAuth();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = async () => {
    setIsConnecting(true);
    try {
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth?.userID,
        },
      });
      const userData = userDetail?.data;

      const authDetail = await axios.get("/api/getauthdata", {
        params: {
          userID: auth?.userID,
        },
      });
      const authData = authDetail?.data;

      await axios.post("/api/mentor-connect", {
        mentorName: name,
        mentorProfession: profession,
        userName: `${userData.firstname} ${userData.lastname}`,
        userEmail: authData.email,
        userID: auth?.userID,
        contactNumber: authData.phno
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error("Error connecting with mentor:", error);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <>
      {showSuccess && (
        <div className="popup-overlay">
          <div className="success-popup">
            <div className="success-icon">✓</div>
            <h3>Connection Request Sent!</h3>
            <p>Great! {name} will connect with you shortly via email.</p>
          </div>
        </div>
      )}
      
      <div className="mentorCardWrapper">
        <div className="mentorCardImgWrapper">
          <img className="mentorCardImg" src={profileImg} alt="/" />
          <div className="mentorCardConnect">
            <button 
              className="yellowBtnConnect"
              onClick={handleConnect}
              disabled={isConnecting}
            >
              {isConnecting ? "Connecting..." : "Connect"}
            </button>
          </div>
        </div>
        <div className="mentorCardDetails">
          <div className="mentorDetail">
            <div className="mentorField">Name : </div>
            <div className="fieldInfo">{name}</div>
          </div>
          <div className="mentorDetail">
            <div className="mentorField">Location :</div>
            <div className="fieldInfo">{location}</div>
          </div>
          <div className="mentorDetailRow">
            <div className="mentorField">About the Mentor :</div>
            <div className="fieldInfo">{description}</div>
          </div>
          <div className="mentorDetailRow">
            <div className="mentorField">Profession :</div>
            <div className="fieldInfo">{profession}</div>
          </div>
          <div className="mentorDetail">
            <div className="mentorField">Review :</div>
            <div className="fieldInfo">{review_stars} ★</div>
          </div>
          <div className="mentorDetail">
            <div className="mentorField">Students Mentored :</div>
            <div className="fieldInfo">{students_mentored}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorCard;